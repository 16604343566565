@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* background-color: black; */
}

@keyframes table-loader {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-table-loader {
  animation: table-loader 1.5s infinite;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  backface-visibility: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Add this CSS to your component's stylesheet or global stylesheet temporarily */ 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

/* CSS */
.button-85 {
  padding: 0.3em 1em;
  border: none;
  outline: none;
  /* color: rgb(255, 255, 255); */
  background: #d8d5e6;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px !important;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: opacity 0.6s ease-in-out;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #00b3ff,
    #008cff,
    #2c42b2,
    #5027b2,
    #6f23ff,
    #00ffdd
  );
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 400%;
  z-index: -1;
  filter: blur(7px);
  -webkit-filter: blur(7px);
  width: calc(100% + 10px);
  height: calc(100% + 5px);
  animation: glowing-button-85 30s linear infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transition: opacity 0.6s ease-in-out;
  border-radius: 50px !important;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }

  0% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  border-radius: 50px !important;
}

.loader {
  /* position: relative; */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(#6f23ff, #00ffdd, #008cff);
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#6f23ff, #00ffdd, #008cff);
}

.loader span:nth-child(1) {
  filter: blur(5px);
}

.loader span:nth-child(2) {
  filter: blur(10px);
}

.loader span:nth-child(3) {
  filter: blur(25px);
}

.loader span:nth-child(4) {
  filter: blur(50px);
}

.loader:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: white;
  /* border: solid rgb(87, 87, 87) 1px; */
  border-radius: 50%;
}

/* CSS */
.tailwind-button {
  transition: ease-in-out 0.15s;
  background: #ffffff;
  border: 1px #d1d5db solid !important;
  border-radius: 0.5rem !important;
  box-sizing: border-box !important;
  color: #111827;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  padding: 0.65rem 1rem !important;
  text-align: center !important;
  text-decoration: none #d1d5db solid !important;
  text-decoration-thickness: auto !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  cursor: pointer !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  text-transform: none !important;
}

.tailwind-button:hover {
  background: rgb(249, 250, 251) !important;
  transition: ease-in-out 0.15s;
}

.tailwind-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tailwind-button:focus-visible {
  box-shadow: none;
}

/* CSS red */
.tailwind-button-red {
  background-color: #f5ebeb !important;
  border: 0;
  border-radius: 0.5rem !important;
  box-sizing: border-box !important;
  color: #c02424 !important;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  padding: 0.75rem 1rem !important;
  text-align: center !important;
  text-decoration: none #d1d5db solid !important;
  text-decoration-thickness: auto !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  cursor: pointer !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  text-transform: none !important;
}

.tailwind-button-red:hover {
  background-color: rgb(249, 250, 251);
}

.tailwind-button-red:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tailwind-button-red:focus-visible {
  box-shadow: none;
}

/* CSS green */
.tailwind-button-green {
  background-color: #24c03b !important;
  border: 0;
  border-radius: 0.5rem !important;
  box-sizing: border-box !important;
  color: white !important;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  padding: 0.75rem 1rem !important;
  text-align: center !important;
  text-decoration: none #d1d5db solid !important;
  text-decoration-thickness: auto !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  cursor: pointer !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  text-transform: none !important;
}

.tailwind-button-green:hover {
  background-color: rgb(249, 250, 251);
}

.tailwind-button-green:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tailwind-button-green:focus-visible {
  box-shadow: none;
}

/* CSS #6f23ff */
.tailwind-button-purple {
  background-color: #6f23ff !important;
  border: 0;
  border-radius: 0.5rem !important;
  box-sizing: border-box !important;
  color: white !important;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  padding: 0.75rem 1rem !important;
  text-align: center !important;
  text-decoration: none #d1d5db solid !important;
  text-decoration-thickness: auto !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  cursor: pointer !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  text-transform: none !important;
}

.tailwind-button-green:hover {
  background-color: rgb(249, 250, 251);
}

.tailwind-button-green:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tailwind-button-green:focus-visible {
  box-shadow: none;
}

#cont {
  background: -webkit-linear-gradient(left top, #6f23ff 20%, #00ffdd 100%);
  width: 53px;
  height: 53px;
  border-radius: 1000px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threed-text {
  color: #302a30;
  font-size: 6rem;
  line-height: normal;
  font-weight: bold;
  text-shadow: 0 1px 0 #6f23ff, 0 2px 0 #e32ef7, 0 3px 0 #6f23ff,
    0 4px 0 #00ffdd, 0 5px 0 #6f23ff, 0 6px 1px rgba(91, 17, 17, 0.1),
    0 0 5px rgba(51, 67, 210, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3),
    0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}

.hint_pulse {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #7023ff5d;
  animation: 1.5s pulse infinite ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #6f23ff;
  }

  100% {
    box-shadow: 0 0 0 14px #69ffa800;
  }
}
